<template>
  <div>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card>
      <v-toolbar small color="white" flat>
        <v-toolbar-title>Pago recibido</v-toolbar-title>

        <v-divider class="mx-2" inset vertical> </v-divider>

        <v-btn
          color="primary"
          small
          class="ma-2 white--text"
          v-if="pago.status_id == 'PMNT_NOT_PAID'"
        >
          <v-icon left>mdi-send</v-icon>
          Completar
        </v-btn>

        <v-btn
          color="primary"
          small
          class="ma-2 white--text"
          v-if="pago.status_id == 'PMNT_RECEIVED'"
          @click="anularPago()"
        >
          <v-icon left>mdi-cancel</v-icon>
          Anular
        </v-btn>

        <v-btn
          color="blue"
          small
          class="ma-2 white--text"
          @click="verAplicaciones"
          v-if="estaAnulado == false"
        >
          <v-icon left>mdi-order-bool-ascending-variant</v-icon>
          Aplicar pago
        </v-btn>

        <v-btn
          color="blue"
          small
          class="ma-2 white--text"
          @click="solicitarReembolso"
          v-if="
            estaAnulado == false &&
            (pago.amount - pago.pagado).toFixed(2) > 0 &&
            puedoSolicitarReembolsoCliente
          "
        >
          <v-icon left>mdi-cash-refund</v-icon>
          Solicitar reembolso
        </v-btn>

        <v-btn
          color="blue"
          small
          class="ma-2 white--text"
          @click="descargarPdf(pago.clave_acceso)"
          v-if="
            pago.clave_acceso != null && pago.payment_type_id == 'RETENCION'
          "
        >
          <v-icon>mdi-file-pdf</v-icon>
          Descargar Comprobante
        </v-btn>

        <v-btn
          color="blue"
          small
          class="ma-2 white--text"
          @click="solicitarPago"
          v-if="
            estaAnulado == false &&
            (pago.amount - pago.pagado).toFixed(2) > 0 &&
            puedoSolicitarPagoServicioTerceros
          "
        >
          <v-icon left>mdi-cash-sync</v-icon>
          Solicitar pago
        </v-btn>

        <v-btn
          color="blue"
          small
          class="ma-2 white--text"
          @click="cruzarCuentas"
          v-if="
            estaAnulado == false &&
            (pago.amount - pago.pagado).toFixed(2) > 0 &&
            puedoSolicitarPagoServicioTerceros
          "
        >
          <v-icon left>mdi-account</v-icon>
          Cruce de cuentas
        </v-btn>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-row>
        <v-col class="pl-12 pt-6 pb-6">
          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                label="Codigo"
                :readonly="true"
                class="md-6 white--text"
                v-model="pago.payment_id"
                small
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>

            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                label="Fecha"
                :readonly="true"
                class="md-6 white--text"
                v-model="pago.effective_date"
                small
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="12" md="12" class="pt-0 pb-0">
              <v-text-field
                :readonly="true"
                label="Cliente"
                class="md-6 white--text"
                v-model="pago.cliente + ' - ' + pago.party_id_from"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                :readonly="true"
                label="Forma de pago"
                class="md-6"
                v-model="pago.forma_pago"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-row
                class="pt-0 pb-0"
                v-if="pago.payment_type_id == 'RETENCION'"
              >
                <v-col sm="6" md="6" class="pt-0 pb-0">
                  <v-text-field
                    :readonly="true"
                    label="Monto base"
                    class="shrink"
                    v-if="pago.payment_type_id == 'RETENCION'"
                    v-model="pago.monto_base"
                    :disabled="estaAnulado == true"
                  >
                  </v-text-field>
                </v-col>
                <v-col sm="6" md="6" class="pt-0 pb-0">
                  <v-text-field
                    :readonly="true"
                    label="Porcentaje"
                    class="shrink"
                    v-if="pago.payment_type_id == 'RETENCION'"
                    v-model="pago.pct_retencion"
                    :disabled="estaAnulado == true"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-col sm="6" md="6" class="pt-0 pb-0">
                <v-text-field
                  :readonly="true"
                  v-if="esTarjeta"
                  label="Tarjeta de crédito"
                  class="md-12"
                  v-model="pago.card_type"
                  :disabled="estaAnulado == true"
                >
                </v-text-field>
              </v-col>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                :readonly="true"
                v-if="esTransferencia == true"
                label="Cuenta bancaria"
                class="md-6"
                v-model="pago.fin_account_name"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>

            <v-col sm="3" md="3" class="pt-0 pb-0">
              <v-text-field
                :readonly="true"
                v-if="esTransferencia == true"
                label="Transaccion libro banco"
                class="md-6"
                v-model="pago.fin_account_trans_id"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
            <v-col sm="3" md="3" class="pt-0 pb-0">
              <v-text-field
                v-if="esTransferencia == true"
                :readonly="true"
                label="Estado transaccion"
                class="md-6"
                :append-icon="estadoTransaccionBancoIcono"
                v-model="estadoTransaccionBanco"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                :readonly="true"
                label="Monto"
                class="md-6"
                v-model="pago.amount"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>

            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                :readonly="true"
                label="Referencia"
                class="md-6"
                v-model="pago.payment_ref_num"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col sm="6" md="6" class="pt-0 pb-0">
              <v-text-field
                text
                :readonly="true"
                label="Caja"
                class="md-6"
                v-model="pago.caja_id"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
            <v-col sm="3" md="3" class="pt-4 pb-0">
              <v-btn
                link
                color="green"
                text
                target="_new"
                :disabled="estaAnulado"
                v-if="pago.caja_id != null"
                :href="`/ver-caja/${pago.caja_id}`"
                ><v-icon>mdi-eye</v-icon>Ver caja: {{ pago.caja_id }}</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="12" class="pt-0 pb-0">
              <v-text-field
                text
                :readonly="true"
                label="Commentarios"
                class="md-6"
                v-model="pago.comments"
                :disabled="estaAnulado == true"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
        <v-col>
          <h3>Saldo: {{ (pago.amount - pago.pagado).toFixed(2) }}</h3>
          <v-data-table
            :headers="headers"
            :options.sync="options"
            :items="itemsPago"
            :items-per-page="itemsPerPage"
            :loading="loadingTable"
            hide-default-footer
            :key="`list-sales-invoices-${tenantId}`"
            class="elevation-1"
          >
            <template v-slot:item.opcion="{ item }">
              <v-btn
                v-if="pago.payment_type_id != 'CRUCE_NC'"
                icon
                color="red"
                target="_blank"
                @click="borrarAplicacionPago(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </template>

            <template v-slot:item.invoice_number="{ item }">
              <v-btn
                v-if="item.invoice_id != null"
                icon
                color="green"
                target="_blank"
                :to="`/factura-venta/${item.invoice_id}`"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <span v-if="item.invoice_id != null">
                {{ item.codigo_establecimiento }}-{{
                  item.codigo_punto_emision
                }}-{{ item.invoice_number }}
              </span>
            </template>

            <template v-slot:item.to_payment_id="{ item }">
              <span v-if="item.to_payment_id == pago.payment_id">
                <v-btn
                  v-if="item.invoice_id == null"
                  icon
                  color="green"
                  target="_blank"
                  :to="`/ver-pago/${item.payment_id}`"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                {{ item.payment_id }}
              </span>
              <span
                v-if="
                  item.to_payment_id != pago.payment_id &&
                  item.to_payment_id != null
                "
              >
                <v-btn
                  v-if="item.invoice_id == null"
                  icon
                  color="green"
                  target="_blank"
                  :to="`/ver-pago/${item.to_payment_id}`"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>

                {{ item.to_payment_id }}
              </span>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <v-card>
      <v-row>
        <v-col cols="12" md="12">
          <v-expansion-panels accordion v-model="panel">
            <v-expansion-panel class="px-0 pb-0">
              <v-expansion-panel-header color="blue-grey lighten-5">
                ASIENTOS CONTABLES
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <AccountingEntries :asientos="asientos" />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-card>

    <!-- DIALOGO APLICACIONES DEL PAGO-->
    <v-dialog v-model="dialogAplicaciones" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Aplicar pago a</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formAplicacionesPago">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <v-autocomplete
                    v-model="facturaAplicar"
                    :items="facturasPend"
                    item-value="invoice_id"
                    item-text="invoice_number"
                    dense
                    label="Documento"
                    @change="setMonto"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="
                            item.codigo_establecimiento +
                            '-' +
                            item.codigo_punto_emision +
                            '-' +
                            item.invoice_number
                          "
                        ></v-list-item-title>
                        <v-list-item-subtitle
                          v-html="`${item.saldo} - ${item.due_date}`"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="10" md="10" class="pt-0 pb-0">
                  <v-text-field label="Monto" v-model="montoAplicar">
                  </v-text-field>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0"> </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="blue darken-1"
            text
            @click="
              dialogAplicaciones = false;
              overlay = false;
            "
          >
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            solid
            small
            class="white--text"
            @click="aplicarDocPago"
          >
            <v-icon>mdi-check</v-icon>
            Aplicar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOGO PAGO DE SERVICIO-->
    <v-dialog v-model="dialogPagoServicio" persistent max-width="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Pagar a</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formPagoServicio">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12" sm="10" md="10">
                  <v-autocomplete
                    v-model="selProveedor"
                    :items="proveedores"
                    item-value="party_id"
                    item-text="prov_nombre"
                    dense
                    label="Seleccione un proveedor"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="item.prov_nombre"
                        ></v-list-item-title>
                        <!-- <v-list-item-subtitle
                          v-html="`${item.saldo} - ${item.due_date}`"
                        ></v-list-item-subtitle> -->
                      </v-list-item-content>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12" sm="8" md="8" class="pt-0 pb-0">
                  <v-checkbox
                    label="Generar orden de pago"
                    v-model="generarOrdenPago"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="blue darken-1"
            text
            @click="dialogPagoServicio = false"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            solid
            small
            class="white--text"
            @click="enviarSolicitudPagoServicio"
          >
            <v-icon>mdi-check</v-icon>
            Solicitar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- DIALOGO APLICAR A OTRO CLIENTE Y DAR DE BAJA -->
    <v-dialog v-model="dialogBajaPago" persistent max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Tipo de transacción</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formBajaPago">
              <v-row>
                <v-col class="pt-4 pb-0" cols="12" sm="6" md="6">
                  <v-autocomplete
                    v-model="tipoTransac"
                    :items="tiposTransac"
                    item-value="tipo_transac"
                    item-text="nombre_transac"
                    dense
                    label="Tipo de transacción"
                  >
                  </v-autocomplete>
                </v-col>

                <!-- <v-col cols="12" sm="8" md="8" class="pt-0 pb-0"> 
                  <v-text-field  v-if="tipoTransac=='CRUCE_OTRO_CLI'" label="Buscar cliente" v-model="clientePara"></v-text-field>
                </v-col> -->

                <v-col cols="12" sm="12" md="12" class="pt-0 pb-0">
                  <v-autocomplete
                    @keydown.enter="buscarFactura"
                    v-if="tipoTransac == 'CRUCE_OTRO_CLI'"
                    label="Buscar factura"
                    v-model="invoiceIdCruce"
                    :search-input.sync="searchFactura"
                    :items="facturasCliente"
                    item-text="invoice_number"
                    item-value="invoice_id"
                    return-object
                    @change="setMontoOtroCli"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col v-if="tipoTransac != 'CRUCE_OTRO_CLI'" cols="6">
                  <v-text-field
                    readonly
                    v-model="(pago.amount - pago.pagado).toFixed(2)"
                    label="Monto"
                  ></v-text-field>
                </v-col>
                <v-col cols="6" v-if="tipoTransac == 'CRUCE_OTRO_CLI'">
                  <v-text-field
                    readonly
                    label="Saldo factura"
                    v-model="saldoFactura"
                  ></v-text-field>
                </v-col>

                <v-col cols="6" v-if="tipoTransac == 'CRUCE_OTRO_CLI'">
                  <v-text-field
                    label="Monto a Aplicar"
                    v-model="montoAplicar"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            v-if="tipoTransac == 'BAJA_CARTERA'"
            color="blue darken-1"
            solid
            small
            class="white--text"
            @click="bajaPagoCartera"
          >
            <v-icon>mdi-check</v-icon>
            Dar de baja
          </v-btn>

          <v-btn
            v-if="tipoTransac == 'CRUCE_OTRO_CLI'"
            color="blue darken-1"
            solid
            small
            class="white--text"
            @click="aplicarPagoOtroCliente"
          >
            <v-icon>mdi-check</v-icon>
            Aplicar pago
          </v-btn>

          <v-btn
            small
            color="blue darken-1"
            text
            @click="dialogBajaPago = false"
          >
            <v-icon>mdi-cancel</v-icon>
            Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import AccountingEntries from "../general/AccountingEntries";
import ExpandableFilters from "../general/ExpandableFilters";
import Vue from "vue";

export default {
  name: "FormVerPagoComponent",
  components: {
    ExpandableFilters,
    AccountingEntries,
  },
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Codigo", value: "payment_id" },
      { text: "Monto", value: "amount_applied", align: "end" },
      { text: "Factura", value: "invoice_number" },
      { text: "Pago", value: "to_payment_id" },
    ],
    lista: [],
    options: {},
    dialog: false,
    dialogAplicaciones: false,
    dialogPagoServicio: false,
    notifications: false,
    overlay: false,
    pago: {
      estado: "",
      payment_id: "",
      effective_date: "",
      party_id_from: "",
      party_id_to: "",
      payment_type_id: "",
      payment_method_type_id: "",
      pagado_por: "",
      pagado_a: "",
      monto: 0,
      pagado: 0,
      payment_ref_num: "",
      payment_method_id: "",
    },
    itemsEstado: [
      { estadoId: "INVOICE_READY", estadoNombre: "Listo" },
      { estadoId: "INVOICE_PAID", estadoNombre: "Pagado" },
    ],
    itemsPago: [],

    headItems: [
      { text: "Opciones", value: "opcion", align: "center", width: 50 },
      { text: "No.", value: "secId", align: "start" },
      { text: "Descripcion", value: "description", align: "start" },
      { text: "Cantidad", value: "cantidad", align: "end" },
      { text: "Precio", value: "valorUnitario", align: "end" },
      { text: "%IVA", value: "pct_iva", align: "end" },
      { text: "IVA", value: "valor_iva", align: "end" },
      { text: "Total", value: "total", align: "end" },
    ],
    headPagos: [
      {
        text: "Tipo",
        value: "payment_method_type",
        align: "center",
        width: 150,
      },
      { text: "Referencia", value: "payment_ref_num", align: "start" },
      { text: "Fecha", value: "effective_date", align: "start" },
      { text: "Monto", value: "amount_applied", align: "start" },
    ],
    proveedores: [],
    selProveedor: "",
    asientos: [],
    panel: "",
    total_pagado: "",
    currentPage: 0,
    pageCount: 1,
    itemsPerPage: 20,
    asientos: [],
    facturasPend: [],
    facturaAplicar: "",
    montoAplicar: 0,

    cuentasBancarias: [],
    finAccountRule: [(v) => !!v || "El campo es obligatorio"],
    refRule: (v) => !!v || "El campo es requerido",
    existe_pago_cheque: false,
    dialogBajaPago: false,
    tiposTransac: [
      {
        tipo_transac: "CRUCE_OTRO_CLI",
        nombre_transac: "Cruce con otro cliente",
      },
      { tipo_transac: "BAJA_CARTERA", nombre_transac: "Baja de cartera" },
    ],
    tipoTransac: "",
    invoiceIdCruce: "",
    facturasCliente: [],
    searchFactura: "",
    generarOrdenPago: false,
  }),
  watch: {
    options: {
      handler() {
        this.cargarPago(false);
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("master", [
      "loadingTable",
      "user",
      "tenantId",
      "paramAlertQuestion",
    ]),

    ...mapGetters("access", [
      "puedoSolicitarReembolsoCliente",
      "puedoSolicitarPagoServicioTerceros",
    ]),
    saldoFactura() {
      return (
        parseFloat(this.invoiceIdCruce.sub_total_imp1) +
        parseFloat(this.invoiceIdCruce.sub_total_imp2) +
        parseFloat(this.invoiceIdCruce.total_iva) -
        parseFloat(
          this.invoiceIdCruce.pagado != null ? this.invoiceIdCruce.pagado : 0
        )
      );
    },
    estadoTransaccionBancoIcono() {
      return this.pago.gl_reconciliation_id == null
        ? "mdi-close-circle"
        : "mdi-check-circle";
    },
    estadoTransaccionBanco() {
      return this.pago.gl_reconciliation_id == null
        ? "No conciliada"
        : "Conciliada";
    },
    refExiste() {
      if (this.valorChequeEc == 0 || this.valorChequeEc == "")
        return "No se encontró la referencia";
      else return true;
    },

    validarRefCosto() {
      if (
        this.datosDevCheque.refCosto != "" &&
        (this.costoChequeEc == 0 || this.costoChequeEc == "")
      ) {
        return "No se encontró una transacción";
      } else {
        return true;
      }
    },
    esTransferencia() {
      if (
        this.pago.payment_method_type_id == "EFT_ACCOUNT" ||
        this.pago.payment_method_type_id == "FIN_ACCOUNT"
      ) {
        return true;
      }
      return false;
    },
    estaPagado() {
      if (this.pago.status_id == "PMNT_NOT_PAID") {
        return true;
      }

      return false;
    },
    estaAnulado() {
      if (this.pago.status_id == "PMNT_VOID") {
        return true;
      }
      return false;
    },
    esTarjeta() {
      if (
        this.pago.payment_method_type_id == "CREDIT_CARD" ||
        this.pago.payment_method_type_id == "DEBIT_CARD" ||
        this.pago.payment_method_type_id == "CREDIT_CARD_DIF" ||
        this.pago.payment_method_type_id == "CREDIT_CARD_DIF_INT"
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    cargarPago() {
      this.setLoadingTable(true);
      this.setUrl("pago-recibido/" + this.$route.params.pagoId);
      this.lista = [];
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
          this.pago = res.data.detail.pago.payment;
          this.itemsPago = res.data.detail.pago.payapp;
          this.asientos = res.data.detail.pago.asientos;
          this.facturasPend =
            res.data.detail.facturas_pendientes != null
              ? res.data.detail.facturas_pendientes
              : [];
          this.proveedores = res.data.detail.proveedores;
          this.setLoadingTable(false);
          //console.log(this.pago)
          this.setTitleToolbar(
            "PAGO #" +
              this.pago.payment_id +
              " - " +
              this.pago.tipo_pago +
              " - " +
              this.pago.estado
          );
        })
        .then(() => {});
    },

    handlePageChange(value) {
      this.cargarLista(false);
    },

    cargarCuentasBancarias() {
      this.setLoadingTable(true);

      this.setUrl("cuentas-bancarias");
      this.requestApi({
        method: "GET",
        data: {},
      }).then((res) => {
        this.cuentasBancarias = res.data._embedded.cuentas_bancarias;
        this.setLoadingTable(false);
      });
    },

    /*getFormasPago() {
      this.setUrl("tipo-pago/CUSTOMER_PAYMENT/formas-pago");
      this.requestApi({
        method: "GET",
        data: {},
      })
        .then((res) => {
           
        })
        .then(() => {});
    },*/

    verAplicaciones() {
      if (this.pago.amount - this.pago.pagado == 0) {
        this.alertNotification({
          param: {
            html: "El pago no tiene saldo para aplicar",
            timer: 5000,
          },
        });
      }
      this.facturaAplicar = "";
      this.montoAplicar = 0;

      if (this.pago.amount - this.pago.pagado > 0) {
        this.dialogAplicaciones = true;
      }
    },

    anularPago() {
      //alert('anulando pago')

      Vue.swal({
        html: "Está seguro de anular este pago ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.overlay = true;
          this.setUrl("anular-pago");
          this.requestApi({
            method: "POST",
            data: {
              paymentId: this.pago.payment_id,
            },
          })
            .then((res) => {
              //console.log(res)
              this.cargarPago();
            })
            .catch(() => {})
            .then(() => {
              this.overlay = false;
            });
        }
      });
    },
    aplicarDocPago() {
      this.overlay = true;

      if (this.facturaAplicar == null || this.facturaAplicar == "") {
        alert("Debe seleccionar un documento para aplicar el pago");
        return false;
      }
      if (
        (this.pago.amount - this.pago.pagado).toFixed(2) < this.montoAplicar
      ) {
        alert(
          "El saldo para aplicar es insuficiente. " +
            (this.pago.amount - this.pago.pagado).toFixed(2) +
            "::" +
            this.montoAplicar
        );
        return false;
      }
      this.setUrl("payment-application");
      this.requestApi({
        method: "POST",
        data: {
          payment_id: this.pago.payment_id,
          invoice_id: this.facturaAplicar,
          amount_applied: this.montoAplicar,
        },
      })
        .then((res) => {
          this.dialogAplicaciones = false;

          this.cargarPago();
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 5000,
            },
          });
        })
        .catch(() => {})
        .then((res) => {
          this.overlay = false;
          this.dialogAplicaciones = false;
        });
    },
    borrarAplicacionPago(pagoApp) {
      Vue.swal({
        html: "Está seguro de eliminar este pago?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("pago-recibido");
          this.requestApi({
            method: "DELETE",
            data: {
              payment_application_id: pagoApp.payment_application_id,
              payment_id: pagoApp.payment_id,
            },
          }).then((res) => {
            //this.dialogPago = false;
            //this.formInvoice(this.$route.params.invoiceId)
            this.cargarPago();
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
          });
        }
      });
    },
    setMonto() {
      //console.log(this.facturaAplicar)
      for (let i = 0; i < this.facturasPend.length; i++) {
        if (this.facturasPend[i].invoice_id == this.facturaAplicar) {
          if (
            (this.pago.amount - this.pago.pagado).toFixed(2) >
            this.facturasPend[i].saldo
          ) {
            this.montoAplicar = this.facturasPend[i].saldo;
          } else {
            this.montoAplicar = (this.pago.amount - this.pago.pagado).toFixed(
              2
            );
          }
        }
      }
    },

    setMontoOtroCli() {
      //this.saldoFactura;
      //this.montoAplicar;

      if (
        (this.pago.amount - this.pago.pagado).toFixed(2) > this.saldoFactura
      ) {
        this.montoAplicar = this.saldoFactura;
      } else {
        this.montoAplicar = (this.pago.amount - this.pago.pagado).toFixed(2);
      }
    },

    solicitarReembolso() {
      Vue.swal({
        html: "Está seguro de solicitar el reembolso de este pago?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crear-reembolso");
          this.requestApi({
            method: "POST",
            data: {
              payment_id: this.pago.payment_id,
              monto: (this.pago.amount - this.pago.pagado).toFixed(2),
            },
          }).then((res) => {
            //this.dialogPago = false;
            //this.formInvoice(this.$route.params.invoiceId)
            this.cargarPago();
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });
          });
        }
      });
    },
    solicitarPago() {
      this.dialogPagoServicio = true;
    },

    enviarSolicitudPagoServicio() {
      Vue.swal({
        html: "Está seguro de solicitar el pago al proveedor del servicio ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("crear-doc-por-pagar");
          this.requestApi({
            method: "POST",
            data: {
              proveedor_id: this.selProveedor,
              payment_id: this.pago.payment_id,
              monto: (this.pago.amount - this.pago.pagado).toFixed(2),
              generarOrdenPago: this.generarOrdenPago,
            },
          }).then((res) => {
            console.log(res);
            this.alertNotification({
              param: {
                html: res.data.detail.msg,
              },
            });

            this.cargarPago();
          });
        }
      });
    },

    descargarPdf(clave_acceso) {
      //console.log(this.tipoDocumento)

      this.overlay = true;
      this.setUrl("comprobante-electronico");

      this.requestApi({
        method: "PATCH",
        data: {
          clave_acceso: clave_acceso,
        },
      })
        .then((res) => {
          //console.log(res)
          var a = document.createElement("a");
          a.href =
            "data:application/pdf;base64," +
            res.data.detail[2]
              .trim()
              .replace('"', "")
              .replace(/(\r\n|\n|\r)/gm, "");
          a.download = "factura" + clave_acceso.substring(24, 39) + ".pdf";
          a.click();
        })
        .catch(() => {})
        .then(() => {
          this.overlay = false;
        });
      //}
    },
    cruzarCuentas() {
      this.dialogBajaPago = true;
    },
    aplicarPagoOtroCliente() {
      if (this.montoAplicar > this.saldoFactura) {
        alert("no se puede");
        return false;
      } else {
        if (
          (this.pago.amount - this.pago.pagado).toFixed(2) < this.montoAplicar
        ) {
          alert("NO HAY SALDO PARA APLICAR");
          return false;
        }
      }

      this.setUrl("payment-application");
      this.requestApi({
        method: "POST",
        data: {
          payment_id: this.pago.payment_id,
          invoice_id: this.invoiceIdCruce.invoice_id,
          amount_applied: this.montoAplicar,
        },
      })
        .then((res) => {
          this.dialogAplicaciones = false;

          this.cargarPago();
          this.alertNotification({
            param: {
              html: res.data.msg,
              timer: 5000,
            },
          });
        })
        .catch(() => {})
        .then((res) => {
          this.overlay = false;
          this.dialogAplicaciones = false;
        });
    },
    bajaPagoCartera() {
      this.overlay = true;
      this.setUrl("pago-recibido");

      this.requestApi({
        method: "POST",
        data: {
          tipoTran: "bajaCarteraCc",
          payment_id: this.pago.payment_id,
          monto: (this.pago.amount - this.pago.pagado).toFixed(2),
        },
      })
        .then((res) => {
          console.log(res);
          this.overlay = false;
          this.dialogBajaPago = false;
          this.cargarPago();
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
        });
    },
    buscarFactura() {
      this.overlay = true;
      this.setUrl("pago-recibido");

      this.requestApi({
        method: "POST",
        data: {
          tipoTran: "buscarFactura",
          busFactura: this.searchFactura,
        },
      })
        .then((res) => {
          console.log(res);
          this.overlay = false;
          this.facturasCliente = res.data.detail;
          //this.dialogBajaPago=false;
          //this.cargarPago();
        })
        .catch(() => {
          this.overlay = false;
        })
        .then(() => {
          this.overlay = false;
        });
    },
  },

  mounted() {
    //this.geEstablishment()
    //this.getFormasPago()
    this.cargarCuentasBancarias();
  },
};
</script>
